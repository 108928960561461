import './App.scss';

import React, { useRef } from 'react'
import { AiFillInstagram, AiFillLinkedin, AiFillBehanceSquare } from 'react-icons/ai';
import { VscLinkExternal } from 'react-icons/vsc';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-66285703-1');
ReactGA.pageview(window.location.pathname + window.location.search);


function Project01() {
  return (
    <div className="projectSection">
      <div className="content">
        <img src="/image/project_eao_2x.jpg" className="projectImage"></img>
        <div className="projectDescription">
          <div className="projectDescription00"><h1>We made shopping for buttons easy with automated 3D renderings.</h1></div>
          <div className="projectDescription01"><p>
            EAO is a swiss company that makes Human-Machine Interfaces or <i>Buttons</i>, <i>Knobs</i>, and <i>Joysticks</i>. 
            In the past, most of their products were sold through a physical catalog or 3rd party online stores, 
            which is why they asked us to come up with an EAO-owned digital distribution channel. 
            Being part of the Project Vision and UX team, we came up with a product configurator of the highest quality. 
            It makes its products look most appealing and easy to understand with automated server-side 3D renderings. 
            And it offers a seamless user experience throughout the shopping process with filter options carrying over from different views. 
            In addition to being a UX Designer for that project, 
            I made carefully crafted 3D key visuals and worked on the visual language of the aforementioned automated renders.
          </p></div>
          <div className="projectDescription02"><p><b>My contribution</b></p><p>UX Design, Project Vision, 3D Key Visuals, Art Direction for automated renders</p><p className="projectDescriptionYear">Made in 2019 - 2020</p></div>
          <div className="projectDescription03"><p className="projectDescription03Title"><b>See more</b></p><ul><li><VscLinkExternal className="externalLink" /><a href="https://eyekon.ch/projekte/eao-3d-konfigurator" target="_blank">Documentation (DE)</a></li><li><VscLinkExternal className="externalLink" /><a href="https://digikey.eao.com" target="_blank">Visit website</a></li></ul></div>
        </div>
      </div>
    </div>
  )
}
function Project02() {
  return (
    <div className="projectSection">
      <div className="content">
        <img src="/image/project_medidata_2x.jpg" className="projectImage"></img>
        <div className="projectDescription">
          <div className="projectDescription00"><h1>Me being multidisciplinary ensured a cohesive brand experience.</h1></div>
          <div className="projectDescription01"><p>
          MediData is <i>the</i> company responsible for the secure transmission of medical data in Switzerland. 
          While changing their business model and in the process of rebuilding their technical infrastructure, 
          MediData asked us to support them through the reorientation and with their rebranding. 
          Being a multidisciplinary designer, I contributed to all parts of this endeavor. 
          First, I laid out the foundation for the rebranding with initial sketches and mockups. 
          Then, I worked on the UX and UI Design of their website and customer portal. 
          Meanwhile, I developed brand imagery and was a Graphic Designer for an image brochure and annual report. 
          Furthermore, I rendered 3D product shots, designed the packaging for some hardware, 
          and created a 3D animation for a launch video. Being this adaptable and thus having a more significant influence 
          on the project was most rewarding and made designing a cohesive brand experience easy.
        </p></div>
          <div className="projectDescription02"><p><b>My contribution</b></p><p>Art Direction, UX/UI Design, Graphic Design (digital and print), 3D Illustration, Packaging Design, 3D Animation</p><p className="projectDescriptionYear">Made in 2018 - 2020</p></div>
          <div className="projectDescription03"><p className="projectDescription03Title"><b>See more</b></p><ul><li><VscLinkExternal className="externalLink" /><a href="https://eyekon.ch/projekte/rebranding-medidata" target="_blank">Documentation (DE)</a></li><li><VscLinkExternal className="externalLink" /><a href="https://www.medidata.ch" target="_blank">Visit website</a></li></ul></div>
        </div>
      </div>
    </div>
  )
}

function Project03() {
  return (
    <div className="projectSection">
      <div className="content">
        <img src="/image/project_weekly_2x.jpg" className="projectImage"></img>
        <div className="projectDescription">
          <div className="projectDescription00"><h1>I gave my passion the attention it deserves.</h1></div>
          <div className="projectDescription01"><p>
            This project is a series of 3D renderings that I worked on personally to improve my 3D software skills. 
            For half a year, I challenged myself to make a weekly rendering with a learning goal for every piece. 
            As a self-taught 3D generalist, focusing on this render series, in the end, landed me a job in a scientific illustration studio.          </p></div>
          <div className="projectDescription02"><p><b>Experience</b></p><p>3D Art/Animation made with Cinema 4D, Redshift and Houdini.</p><p className="projectDescriptionYear">Made in 2020</p></div>
          <div className="projectDescription03"><p className="projectDescription03Title"><b>See more</b></p><ul><li><AiFillBehanceSquare /><a href="https://www.behance.net/gallery/113419591/weekly-renders" target="_blank">Visit collection</a></li></ul></div>
        </div>
     </div>
    </div>
  )
}

function Project04() {
  return (
    <div className="projectSection">
      <img src="/image/project_compona.jpg" className="projectImage"></img>
      <div className="projectDescription">
        <div className="projectDescription01"><h1>Compona - Online Store</h1><p>Relaunch of a catalogue with 40’000 articles as a webshop. A project that won three awards at Best of Swiss Web.</p></div>
        <div className="projectDescription02"><p>UX/UI Design &nbsp;//&nbsp; 2018</p></div>
        <div className="projectDescription03"><ul><li><VscLinkExternal className="externalLink" /><a href="https://eyekon.ch/projekte/compona" target="_blank">Read more (DE)</a></li></ul></div>
      </div>
    </div>
  )
}

function Project05() {
  return (
    <div className="projectSection">
      <img src="/image/project_responsability.jpg" className="projectImage"></img>
      <div className="projectDescription">
        <div className="projectDescription01"><h1>responsAbility - Video Series</h1><p>A video series to raise awareness of the environmental and social risks involved in planning and building green energy projects.</p></div>
        <div className="projectDescription02"><p>3D Animation &nbsp;//&nbsp; 2020 - 2021</p></div>
        <div className="projectDescription03"><ul><li><VscLinkExternal className="externalLink" /><a href="https://quaint.ch/E-S-Risks-of-Green-Energy-Projects" target="_blank">Read more</a></li></ul></div>
     </div>
    </div>
  )
}

function Project06() {
  return (
    <div className="projectSection">
      <img src="/image/project_uzhnow.jpg" className="projectImage"></img>
      <div className="projectDescription">
        <div className="projectDescription01"><h1>UZH Now - Campus App</h1><p>A campus app awarded with gold in 'UX/Usability' at Best of Swiss Apps.</p></div>
        <div className="projectDescription02"><p>UX/UI Design, Project Vision &nbsp;//&nbsp; 2018</p></div>
        <div className="projectDescription03"><ul><li><VscLinkExternal className="externalLink" /><a href="https://eyekon.ch/projekte/uzh-app" target="_blank">Read more (DE)</a></li></ul></div>
     </div>
    </div>
  )
}

function Project07() {
  return (
    <div className="projectSection">
      <img src="/image/project_jungfrau.jpg" className="projectImage"></img>
      <div className="projectDescription">
        <div className="projectDescription01"><h1>Jungfrau Region - Website</h1><p>Rebranding and Website for Jungfrau Region, a tourism organisation that represents five tiouristic destinations.</p></div>
        <div className="projectDescription02"><p>UX/UI Design &nbsp;//&nbsp; 2018</p></div>
        <div className="projectDescription03"><ul><li><VscLinkExternal className="externalLink" /><a href="https://eyekon.ch/projekte/jungfrau-region" target="_blank">Read more (DE)</a></li></ul></div>
     </div>
    </div>
  )
}

function Project08() {
  return (
    <div className="projectSection">
      <img src="/image/project_mincedcartilage.jpg" className="projectImage"></img>
      <div className="projectDescription">
        <div className="projectDescription01"><h1>Geistlich - Key Visual</h1><p>Key Visual to demonstrate Geistlich’s latest technology in biomaterials with 'Minced Cartilage'.</p></div>
        <div className="projectDescription02"><p>3D Scientific Illustration &nbsp;//&nbsp; 2021</p></div>
     </div>
    </div>
  )
}

function Project09() {
  return (
    <div className="projectSection">
      <img src="/image/project_migros.jpg" className="projectImage"></img>
      <div className="projectDescription">
        <div className="projectDescription01"><h1>Migros - Animation</h1><p>A series of animations to explain parts of Migros' future supply chain. </p></div>
        <div className="projectDescription02"><p>Development of Visual Language, 3D Modelling, Animation &nbsp;//&nbsp; 2021</p></div>
        <div className="projectDescription03"><ul><li><VscLinkExternal className="externalLink" /><a href="https://quaint.ch/Future-Supply-Chain" target="_blank">Read more</a></li></ul></div>
     </div>
    </div>
  )
}

function Footer() {
  return (
    <div className="footer">
      <div className="footerText content">
        <p>Want to see more? <a href="mailto:hi@giacomo.io" target="_blank">Get in touch</a></p>
      </div>
    </div>
  )
}

export default function App() {

  const projectsRef = useRef(null)

  const executeScroll = () => projectsRef.current.scrollIntoView()    
  // run this function from an event handler or an effect to execute scroll 

  return (
    <>
      <div className="backround"></div>
      <div className="stage content">
        <div className="header">
          <div className="headerLogo">
            <video className="headerLogoVideo" playsInline autoPlay muted loop width="240" poster="video/gp_icon240.png">
              <source src="/video/gp_icon_240_alpha.mov" type="video/quicktime" />
              <source src="/video/gp_icon_240_alpha.webm" type="video/webm" />
            </video>
          </div>
          <div className="headerNav">
            <a onClick={executeScroll} className="headerNavWork">Work</a>
          </div>
        </div>
        <div className="stageIntro">
          <h1><span>Giacomo Pedemonte.</span> Designer of digital products. Creator of delightful UI with thoughtful UX. Passionate maker of 3D computer graphics. Interaction Designer. Art Director. 3D Generalist. Based in Zurich.</h1>
          <div className="stageCta">
          <p>
            <a href="mailto:hi@giacomo.io" target="_blank"><button>Say Hello!</button></a>
            <a href="https://www.instagram.com/fumoto3000/" target="_blank" className="stageCtaInstagram stageCtaIcon"><AiFillInstagram /></a>
            <a href="https://www.linkedin.com/in/giacomopedemonte/" target="_blank" className="stageCtaLinkedin stageCtaIcon"><AiFillLinkedin /></a>
            <span>🤝&nbsp; I’m available for hire.</span>
          </p>
        </div>
        </div>
      </div>
      <div className="projectSectionMain" ref={projectsRef}>
        <Project01 />
        <Project02 />
        <Project03 />
      </div>
      <div className="projectSectionGrid content">
        <Project07 />
        <Project04 />
        <Project05 />
        <Project06 />
        <Project09 />
        <Project08 />
      </div>
      <Footer />
    </>
  )
}
